<template>
  <div class="chats">
    <div
      v-for="(msgGrp, index) in formattedChatData.msgGroup"
      :key="msgGrp.userId+String(index)"
      class="chat"
      :class="{'chat-left': msgGrp.autoChat !== true && msgGrp.profilePartnerId !== null, }"
    >
      <div class="chat-avatar">
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
          :src="msgGrp.profilePartnerId !== null ? formattedChatData.userInfo.image : profileUserAvatar"
        />
      </div>
      <div class="chat-body">
        <div class="chat-content">
          <div v-if="msgGrp.type === 'text'">
            <p>{{ msgGrp.messages }}</p>
          </div>
          <div v-if="msgGrp.type === 'picture'">
            <b-img
              v-if="msgGrp.messages"
              :src="msgGrp.messages"
              fluid
              thumbnail
              width="100px"
              :alt="'Chat_'+index"
            />
            <b-img
              v-else
              :src="require('@/assets/images/blank/no_image.png')"
              fluid
              thumbnail
              width="100px"
              :alt="'Chat_'+index"
            />
          </div>
          <p>{{ msgGrp.created | formatDateTime }}</p></div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { BAvatar, BImg } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BImg,
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
    profileUserAvatar: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const formattedChatData = computed(() => {
      const userInfo = {
        id: props.chatData.profilePartner._id,
        userId: props.chatData.profilePartner._id,
        image: props.chatData.profilePartner.image,
      }

      let chatLog = []
      if (props.chatData.data) {
        chatLog = props.chatData.data
      }
      const msgGroup = []

      // eslint-disable-next-line no-unused-vars
      chatLog.forEach((msg, index) => {
        if (msg.adminId != null) {
          msgGroup.push({
            profilePartnerId: null,
            adminId: msg.adminId,
            messages: msg.message,
            type: msg.type,
            created: msg.created,
            autoChat: msg.autoChat,
          })
        } else {
          msgGroup.push({
            profilePartnerId: msg.userId,
            adminId: null,
            messages: msg.message,
            type: msg.type,
            created: msg.created,
            autoChat: msg.autoChat,
          })
        }
      })
      return {
        msgGroup,
        userInfo,
        profileUserAvatar: props.profileUserAvatar,
      }
    })

    return {
      formattedChatData,
    }
  },
}
</script>

<style>

</style>
